import request from './index'

export const getList = (data) => {
  return request({
    method: 'GET',
    url: '/instagram/list',
    params: data
  })
}
export const mediatorList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/mediator',
    params: data
  })
}
export const agencyList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/agency',
    params: data
  })
}

export const deleteInstagram = (data) => {
  return request({
    method: 'PUT',
    url: '/instagram/delete',
    data: data
  })
}

export const addInstagram = (data) => {
  return request({
    method: 'POST',
    url: '/instagram/add',
    data: data
  })
}

export const infDetail = (data) => {
  return request({
    method: 'POST',
    url: '/instagram/infDetail',
    data: data
  })
}

export const updateInstagram = (data) => {
  return request({
    method: 'PUT',
    url: '/instagram/update',
    data: data
  })
}
