<template>
  <div>
    <b-card>
      <!-- Filter Form -->
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-1"
            inline
          >
            <b-form-select
              class="mr-2"
              v-model="filter.searchType"
              :options="searchOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Search Type</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-input
              class="mr-2"
              v-model="filter.searchValue"
              placeholder="Search Input"
            ></b-form-input>

            <!-- <b-form-select
              class="mr-2"
              v-model="filter.category"
              :options="instaCategoryOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Category</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mr-2"
              v-model="filter.language"
              :options="languageOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >language</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-select
              class="mr-2"
              v-model="filter.gender"
              :options="genderOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Gender</b-form-select-option>
              </template>
            </b-form-select> -->

            <b-form-group
              class="mr-2"
              style="width: 220px; height: auto;"
            >
              <multiselect
                style="width: 220px; height: auto;"
                v-model="selectedCategories"
                :options="instaCategoryOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Category"
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">Categories</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedCategories.length && !isOpen"
                  >{{ selectedCategories.length }} Categories</span>
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group
              class="mr-2"
              style="width: 180px; height: auto;"
            >
              <multiselect
                style="width: 180px; height: auto;"
                v-model="selectedLanguages"
                :options="languageOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Language"
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">Language</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedLanguages.length && !isOpen"
                  >{{ selectedLanguages.length }} Languages</span>
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group
              class="mr-2 mt-2"
              style="width: 180px; height: auto;"
            >
              <multiselect
                style="width: 180px; height: auto;"
                v-model="selectedGender"
                :options="genderOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Gender"
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">Gender</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedGender.length && !isOpen"
                  >{{ selectedGender.length }} Gender</span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              class="mr-2 mt-2"
              style="width: 180px; height: auto;"
            >
              <multiselect
                style="width: 180px; height: auto;"
                v-model="selectedCity"
                :options="city"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="City"
                selectLabel=""
                deselectLabel=""
                label="city"
                track-by="city"
              >
                <span slot="noResult">City</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedCity.length && !isOpen"
                  >{{ selectedCity.length }} City</span>
                </template>
              </multiselect>
            </b-form-group>
            <!-- <b-form-select
              class="mr-2 mt-2"
              v-model="filter.is_business"
              :options="AccountTypeOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Account Type</b-form-select-option>
              </template>
            </b-form-select> -->

            <b-form-select
              class="mr-2 mt-2"
              v-model="filter.is_verify"
              :options="instaVerifyOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Account Verify</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mr-2 mt-2"
              v-model="filter.is_page"
              :options="pageListOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Account Page</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mt-2 mr-2"
              v-model="filter.instaType"
              :options="instaOptions"
              value-field="value"
              text-field="label"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Instagram Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-input-group
              prepend="Followers"
              class="mr-2 mt-2"
            >
              <b-form-input
                v-model="filter.minSubscibers"
                placeholder="Min"
                style="width: 100px;"
              ></b-form-input>
              <b-form-input
                v-model="filter.maxSubscibers"
                placeholder="Max"
                style="width: 100px;"
              ></b-form-input>
            </b-input-group>

            <b-input-group
              prepend="Price"
              class="mr-2 mt-2"
            >
              <b-form-input
                v-model="filter.minPrice"
                placeholder="Min"
                style="width: 100px;"
              ></b-form-input>
              <b-form-input
                v-model="filter.maxPrice"
                placeholder="Max"
                style="width: 100px;"
              ></b-form-input>
            </b-input-group>

            <b-button
              pill
              class="mt-2 mr-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-2 mr-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>

          </b-form>
        </div>
        <b-overlay
          :show="exportLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="!exportLoading"
        >
          <b-dropdown
            class="ml-auto"
            variant="outline-white"
            right
            no-caret
          >
            <template #button-content>
              <b-icon icon="list"></b-icon>
            </template>
            <b-dropdown-item @click="exportdata()">
              <b-icon icon="download"></b-icon>
              Export
            </b-dropdown-item>
            <b-dropdown-item @click="addchannelModal">
              <b-icon icon="person-plus"></b-icon>
              Add Channel
            </b-dropdown-item>
          </b-dropdown>
        </b-overlay>
      </div>

      <hr />

      <!-- Data Table -->
      <div
        class="d-block text-center"
        v-if="!items.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Record Found</h5>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(basic)="data">
          <a
            :href="data.value.insta_link"
            target="_blank"
            class="meta d-flex justify-content-start align-items-center"
          >
            <img :src="`https://ui-avatars.com/api/?background=F54153&color=fff&name=${data.value.insta_name ? data.value.insta_name: 'NA'}`" />
            <p>
              <strong>{{ data.value.insta_name }}</strong> <br>
              <b-badge
                pill
                variant="info"
              >{{ data.value.category }}</b-badge>
            </p>
          </a>
        </template>>
        <template #cell(language)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(gender)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(followers)="data">
          {{ data.value | formatNumber}}
        </template>
        <!-- <template #cell(is_business)="data">
          {{ data.value | accountType}}
        </template> -->
        <template #cell(total_media)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(is_verify)="data">
          {{ data.value | verify}}
        </template>
        <template #cell(is_page)="data">
          {{ data.value | page}}
        </template>
        <template #cell(contact)="data">
          <p v-if="data.value.contact_1 && data.value.contact_1 !== ''">
            {{ data.value.contact_1 }}
          </p>
          <p v-else-if="data.value.contact_2 && data.value.contact_2 !== ''">
            {{ data.value.contact_2 }}
          </p>
          <p v-else>NA</p>
        </template>
        <template #cell(add_time)="data">
          {{ data.value | dateFormat}}
        </template>
        <template #cell(email)="data">
          <p v-if="data.value.email_1 && data.value.email_1 !== ''">
            {{ data.value.email_1 }}
          </p>
          <p v-else-if="data.value.email_2 && data.value.email_2 !== ''">
            {{ data.value.email_2 }}
          </p>
          <p v-else>NA</p>
        </template>
        <template #cell(price)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="outline-white"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item
              target="_blank"
              @click="viewinfDetail(data.item)"
            >
              <b-icon icon="eye"></b-icon>
              View
            </b-dropdown-item>
            <b-dropdown-item @click="editchannelModal(data.item)">
              <b-icon icon="pencil-square"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="deleteChannel(data.item.insta_username)">
              <b-icon icon="trash"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="total > 50 && !loading"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        pills
        @input="handlePageClick"
      ></b-pagination>
      <!-- AddChannel Modal  -->
      <b-modal
        title="Add Channel"
        ref="addChannelModal"
        hide-footer
        :no-close-on-backdrop="true"
        centered
      >
        <validation-observer ref="addchannelrules">
          <b-form>
            <b-form-group label="Resource">
              <validation-provider
                #default="{ errors }"
                name="Resource"
                rules="required"
              >
                <b-form-radio-group
                  v-model="addChannelData.resource"
                  :options="infListOptions"
                  value-field="value"
                  text-field="label"
                  @change="resetuuid"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Mediator"
              v-if="addChannelData.resource === 1"
            >
              <validation-provider
                #default="{ errors }"
                name="Mediator"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2"
                  value-field="uuid"
                  text-field="name"
                  v-model="addChannelData.uuid"
                  :options="mediatorList"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Mediator</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Agency"
              v-if="addChannelData.resource === 2"
            >
              <validation-provider
                #default="{ errors }"
                name="Agency"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2"
                  value-field="uuid"
                  text-field="name"
                  v-model="addChannelData.uuid"
                  :options="agencyList"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Agency</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="InstagramLink">
              <validation-provider
                #default="{ errors }"
                name="InstagramLink"
                :rules="{ required: true, regex: /^https:\/\/www.instagram.com\/([^ /]+)/ }"
              >
                <b-form-input
                  v-model="addChannelData.insta_link"
                  placeholder="https://www.instagram.com/username"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="addChannelData.insta_name"
                  placeholder="Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <multiselect
                  v-model="addChannelData.gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addChannelData.gender && addChannelData.gender.length ? addChannelData.gender.length : 'Select' }} Gender</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <multiselect
                  v-model="addChannelData.category"
                  :state="errors.length > 0 ? false : null"
                  :options="instaCategoryOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addChannelData.category && addChannelData.category.length ? addChannelData.category.length : 'Select' }} Category</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <multiselect
                  v-model="addChannelData.language"
                  :state="errors.length > 0 ? false : null"
                  :options="languageOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addChannelData.language && addChannelData.language.length? addChannelData.language.length : 'Select' }} Language</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Contact 1">
              <validation-provider
                #default="{ errors }"
                name="Contact 1"
                :rules="{ regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.contact_1"
                  placeholder="Contact Number 1"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Contact 2">
              <validation-provider
                #default="{ errors }"
                name="Contact 2"
                :rules="{ regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.contact_2"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Contact Number 2"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 1">
              <validation-provider
                #default="{ errors }"
                name="Email address 1"
                rules="email"
              >
                <b-form-input
                  v-model="addChannelData.email_1"
                  placeholder="Email address 1"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 2">
              <validation-provider
                #default="{ errors }"
                name="Email address 2"
                rules="email"
              >
                <b-form-input
                  v-model="addChannelData.email_2"
                  type="email"
                  placeholder="Email address 2"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Price">
              <validation-provider
                #default="{ errors }"
                name="Price"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.price"
                  placeholder="Influencers latest price"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Followers">
              <validation-provider
                #default="{ errors }"
                name="Followers"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.followers"
                  placeholder="Followers"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Total Media">
              <validation-provider
                #default="{ errors }"
                name="Total Media"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.total_media"
                  placeholder="Total Media"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Account Type">
              <b-form-select
                class="mb-2 mr-sm-2"
                v-model="addChannelData.is_business"
                :options="AccountTypeOptions"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Account Type</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group> -->
            <b-form-group label="Account Verify">
              <b-form-select
                class="mb-2 mr-sm-2"
                v-model="addChannelData.is_verify"
                :options="instaVerifyOptions"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Account Verify</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Account Page">
              <b-form-select
                class="mb-2 mr-sm-2"
                v-model="addChannelData.is_page"
                :options="pageListOptions"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Account Page</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="danger"
                @click="cancelAdd"
              >Cancel</b-button>
              <b-button
                pill
                class="mr-2"
                variant="primary"
                @click="resetChannel"
              >Reset</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="addChannel"
              >Add</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- EditChannel Modal  -->
      <b-modal
        title="Edit Channel"
        ref="editChannelModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group label="Name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="editChannelData.insta_name"
                  placeholder="Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2"
                  v-model="editChannelData.gender"
                  :options="genderOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Gender</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2"
                  v-model="editChannelData.language"
                  :options="languageOptions"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Language</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2"
                  v-model="editChannelData.category"
                  :state="errors.length > 0 ? false : null"
                  :options="instaCategoryOptions"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select category</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <multiselect
                  v-model="editChannelData.gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editChannelData.gender && editChannelData.gender.length ? editChannelData.gender.length : 'Select' }} Gender</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <multiselect
                  v-model="editChannelData.category"
                  :state="errors.length > 0 ? false : null"
                  :options="instaCategoryOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editChannelData.category && editChannelData.category.length ? editChannelData.category.length : 'Select' }} Category</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <multiselect
                  v-model="editChannelData.language"
                  :state="errors.length > 0 ? false : null"
                  :options="languageOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editChannelData.language && editChannelData.language.length? editChannelData.language.length : 'Select' }} Language</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Contact 1">
              <b-form-input
                v-model="editChannelData.contact_1"
                disabled
                placeholder="Contact Number 1"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Contact 2">
              <validation-provider
                #default="{ errors }"
                name="Contact 2"
                :rules="{ regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="editChannelData.contact_2"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Contact Number 2"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 1">
              <validation-provider
                #default="{ errors }"
                name="Email address 1"
                rules="email"
              >
                <b-form-input
                  v-model="editChannelData.email_1"
                  placeholder="Email address 1"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 2">
              <validation-provider
                #default="{ errors }"
                name="Email address 2"
                rules="email"
              >
                <b-form-input
                  v-model="editChannelData.email_2"
                  type="email"
                  placeholder="Email address 2"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Price">
              <validation-provider
                #default="{ errors }"
                name="Price"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="editChannelData.price"
                  placeholder="Influencers latest price"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Followers">
              <validation-provider
                #default="{ errors }"
                name="Followers"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="editChannelData.followers"
                  placeholder="Followers"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Total Media">
              <validation-provider
                #default="{ errors }"
                name="Total Media"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="editChannelData.total_media"
                  placeholder="Total Media"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Account Type">
              <b-form-select
                class="mb-2 mr-sm-2"
                v-model="editChannelData.is_business"
                :options="AccountTypeOptions"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Account Type</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group> -->
            <b-form-group label="Account Verify">
              <b-form-select
                class="mb-2 mr-sm-2"
                v-model="editChannelData.is_verify"
                :options="instaVerifyOptions"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Account Verify</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Account Page">
              <b-form-select
                class="mb-2 mr-sm-2"
                v-model="editChannelData.is_page"
                :options="pageListOptions"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >Select Account Page</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="updateChannel"
              >Update</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- Channel View Modal  -->
      <b-modal
        :title="channelName"
        ref="viewModal"
        :no-close-on-backdrop="true"
        hide-footer
        size="lg"
      >
        <!-- centered -->
        <div
          style="text-align:center"
          class="mb-2"
        > <a
            v-bind:href="channelLink"
            target="_blank"
            class="nav-link"
            style="color:blue;"
          > {{channelLink}}
          </a></div>
        <div
          class="d-block text-center"
          v-if="!infDetaildata.length && !loading2"
        >
          <img
            src="@/assets/images/no-data.png"
            width="100"
          />
          <h5 class="mt-2">No Record Found</h5>
        </div>
        <b-table
          :items="infDetaildata"
          sticky-header="true"
          :fields="inflfields"
          :busy="loading2"
          head-variant="light"
          responsive
          hover
          v-else
        >
          <template #table-busy>
            <div class="text-center text-primary mt-5 mb-1">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(inf_type)="data">
            {{ data.value | infDetail }}
          </template>
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(multimobile)="data">
            {{  data.value.mobile ? ((data.value.mobile ? data.value.mobile : '')+ (data.value.contact_1 && data.value.contact_1 !== data.value.mobile ? (data.value.mobile ? '/' : '') + data.value.contact_1 : '')) : ((data.value.contact_1 ? data.value.contact_1 : '')+ (data.value.contact_2 && data.value.contact_2 !== data.value.contact_1 ? (data.value.contact_1 ? '/' : '') + data.value.contact_2 : '')) }}
          </template>
          <template #cell(multiemail)="data">
            {{ data.value.email ? ((data.value.email ? data.value.email : '') + (data.value.email_1 &&  data.value.email_1 !== data.value.email ? (data.value.email ? '/' : '') + data.value.email_1 : '')) : ((data.value.email_1 ? data.value.email_1 : '') + (data.value.email_2 && data.value.email_2 !== data.value.email_1 ? (data.value.email_1 ? '/' : '') + data.value.email_2 : '')) }}
          </template>
        </b-table>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import constantData from '@/helpers/constant'
import { getList, deleteInstagram, updateInstagram, infDetail, mediatorList, agencyList, addInstagram } from '@/api/instagram'
export default {
  data () {
    return {
      mediatorList: [],
      agencyList: [],
      perPage: 50,
      currentPage: 1,
      editChannelModal: false,
      addChannelModal: false,
      viewModal: false,
      tabledata: [],
      infDetaildata: [],
      loading: true,
      loading2: true,
      exportLoading: false,
      total: 0,
      channelName: null,
      channelLink: null,
      selectedCategories: [],
      selectedCity: [],
      selectedLanguages: [],
      selectedGender: [],
      filter: {
        searchType: null,
        searchValue: null,
        category: null,
        language: null,
        gender: null,
        is_page: null,
        // is_business: null,
        instaType: null,
        city: null,
        is_verify: null,
        p: 1,
        size: 50
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'basic', label: 'Channel' },
        'language',
        'gender',
        { key: 'followers', label: 'followers' },
        { key: 'total_media', label: 'Total Media' },
        { key: 'is_verify', label: 'Account Verify' },
        { key: 'is_page', label: 'Account Page' },
        { key: 'contact', label: 'Contact' },
        { key: 'email', label: 'Email' },
        { key: 'city', label: 'City' },
        'price',
        { key: 'add_time', label: 'Add Time' },
        'action'
      ],
      inflfields: [
        { key: 'index', label: 'Sr.' },
        { key: 'inf_type', label: 'Type' },
        { key: 'name', label: 'Name' },
        { key: 'multiemail', label: 'Email' },
        { key: 'multimobile', label: 'Mobile' }
      ],
      items: [],
      city: [],
      state: [],
      editChannelData: {
      },
      addChannelData: {
        category: null,
        email: null,
        language: null,
        gender: null,
        contact_1: null,
        contact_2: null,
        email_1: null,
        email_2: null,
        followers: null,
        price: null,
        insta_name: null,
        total_media: null,
        resource: null,
        uuid: null,
        is_page: null,
        is_verify: null,
        // is_business: null,
        insta_link: null
      },
      instaOptions: [{
        value: 0,
        label: 'Admin'
      }, {
        value: 1,
        label: 'Signup'
      }],
      searchOptions: [
        { value: 'insta_name', text: 'Channel Name' },
        { value: 'insta_username', text: 'Insta userId' },
        { value: 'contact', text: 'Contact' },
        { value: 'email', text: 'Email' }
      ],
      instaCategoryOptions: constantData.instaCategoryList,
      languageOptions: constantData.language,
      genderOptions: constantData.genderList,
      infListOptions: constantData.infListAdmin,
      pageListOptions: constantData.pageList.map(item => {
        return { value: item.value, text: item.label }
      }),
      instaVerifyOptions: constantData.instaVerifyList.map(item => {
        return { value: item.value, text: item.label }
      }),
      AccountTypeOptions: constantData.instaAccountTypeList.map(item => {
        return { value: item.value, text: item.label }
      })
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    exportdata () {
      this.exportLoading = true
      // On Click Excel download button
      // export json to Worksheet of Excel
      // only array possible
      var InstagramrWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, InstagramrWS, 'InstagramrData') // sheetAName is name of Worksheet

      // export Excel file
      setTimeout(() => {
        XLSX.writeFile(wb, 'Instagram.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    resetFilter () {
      this.filter = {
        searchType: null,
        category: null,
        language: null,
        searchValue: null,
        gender: null,
        instaType: null,
        p: 1,
        size: 50,
        is_page: null,
        // is_business: null,
        is_verify: null
      }
      this.selectedCategories = []
      this.selectedLanguages = []
      this.selectedGender = []
      this.currentPage = 1
      this.selectedCity = []
      this.getData()
    },
    resetuuid () {
      this.addChannelData.uuid = null
    },
    addchannelModal () {
      this.$refs.addChannelModal.show()
      this.mediatData()
      this.agencyData()
    },
    editchannelModal (channel) {
      this.editChannelData = {
        category: channel.basic.category ? channel.basic.category.split(',') : null,
        language: channel.language ? channel.language.split(',') : null,
        gender: channel.gender ? channel.gender.split(',') : null,
        contact_1: channel.contact.contact_1,
        contact_2: channel.contact.contact_2,
        email_1: channel.email.email_1,
        email_2: channel.email.email_2,
        price: channel.price,
        followers: channel.followers,
        insta_name: channel.basic.insta_name,
        insta_username: channel.insta_username,
        total_media: channel.total_media,
        is_page: channel.is_page,
        is_verify: channel.is_verify,
        // is_business: channel.is_business,
        insta_link: channel.insta_link
      }
      this.$refs.editChannelModal.show()
    },
    cancelAdd () {
      this.resetChannel()
      this.$refs.addChannelModal.hide()
    },
    cancelEdit () {
      this.$refs.editChannelModal.hide()
    },
    resetChannel () {
      this.addChannelData = {
        category: null,
        language: null,
        gender: null,
        contact_1: null,
        contact_2: null,
        email_1: null,
        email_2: null,
        price: null,
        resource: null,
        uuid: null,
        followers: null,
        insta_name: null,
        total_media: null,
        is_page: null,
        is_verify: null,
        // is_business: null,
        insta_link: null
      }
    },
    async viewinfDetail (item) {
      this.channelName = item.basic.insta_name
      this.channelLink = item.basic.insta_link
      const data = {
        insta_username: item.insta_username
      }
      this.$refs.viewModal.show()
      this.loading2 = true
      const response = await infDetail(data)
      if (response.succ) {
        this.infDetaildata = response.data.map(item => {
          return {
            inf_type: item.inf_type,
            name: item.name,
            multimobile: {
              mobile: item.mobile,
              contact_1: item.contact_1,
              contact_2: item.contact_2
            },
            multiemail: {
              email: item.email,
              email_1: item.email_1,
              email_2: item.email_2
            }
          }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading2 = false
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      if (this.filter.searchValue && !this.filter.searchType) {
        return this.$bvToast.toast('Please select search type', {
          title: 'Search error!',
          variant: 'danger',
          solid: true
        })
      }
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    async getData () {
      this.loading = true
      this.filter.category = this.selectedCategories.length ? this.selectedCategories.join() : null
      this.filter.language = this.selectedLanguages.length ? this.selectedLanguages.join() : null
      this.filter.gender = this.selectedGender.length ? this.selectedGender.join() : null
      this.filter.city = this.selectedCity.length ? (this.selectedCity.map(a => a.city)).join() : null
      const response = await getList(this.filter)
      if (response.succ) {
        this.tabledata = response.data
        this.total = response.totaluser
        this.city = response.city
        this.state = response.state
        this.items = response.data.map(item => {
          return {
            basic: {
              avatar: item.avatar,
              insta_name: item.insta_name,
              category: item.category,
              insta_link: item.insta_link
            },
            followers: item.followers,
            total_media: item.total_media,
            gender: item.gender,
            contact: {
              contact_1: item.contact_1,
              contact_2: item.contact_2
            },
            email: {
              email_1: item.email_1,
              email_2: item.email_2
            },
            language: item.language,
            price: item.price,
            status: item.status,
            // is_business: item.is_business,
            is_verify: item.is_verify,
            last_action_by: item.last_action_by,
            id: item.id,
            insta_username: item.insta_username,
            is_page: item.is_page,
            add_time: item.add_time,
            city: item.city,
            state: item.state
          }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async agencyData () {
      const response = await agencyList()
      if (response.succ) {
        this.agencyList = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async mediatData () {
      const response = await mediatorList()
      if (response.succ) {
        this.mediatorList = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async deleteChannel (channelId) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete this Instagramr?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            insta_username: channelId,
            status: 1
          }
          if (value) {
            const response = await deleteInstagram(data)
            if (response.succ) {
              this.$bvToast.toast('Instagramer deleted successfully', {
                title: 'Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.items.splice(this.items.findIndex(a => a.insta_username === channelId), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch(() => {
          console.log('cancel delete.')
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updateChannel () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this Instagramr?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                insta_username: this.editChannelData.insta_username,
                status: 2,
                gender: this.editChannelData.gender ? this.editChannelData.gender.join() : null,
                category: this.editChannelData.category ? this.editChannelData.category.join() : null,
                language: this.editChannelData.language ? this.editChannelData.language.join() : null,
                contact_1: this.editChannelData.contact_1,
                contact_2: this.editChannelData.contact_2,
                email_1: this.editChannelData.email_1,
                email_2: this.editChannelData.email_2,
                price: this.editChannelData.price,
                is_page: this.editChannelData.is_page,
                total_media: this.editChannelData.total_media,
                followers: this.editChannelData.followers,
                // is_business: this.editChannelData.is_business,
                is_verify: this.editChannelData.is_verify,
                insta_name: this.editChannelData.insta_name
              }
              if (value) {
                const response = await updateInstagram(data)
                if (response.succ) {
                  this.$bvToast.toast('Instagramer updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editChannelModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch((error) => {
              console.log('cancel update.', error)
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async addChannel () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this Instagramr?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const id = this.addChannelData.insta_link.replace('https://www.instagram.com/', '')
              const userId = id.replace(/[/].*$/g, '') // remove after slash
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                email: window.localStorage.getItem('user-email'),
                insta_link: 'https://www.instagram.com/' + userId,
                insta_username: userId,
                status: 3,
                gender: this.addChannelData.gender ? this.addChannelData.gender.join() : null,
                category: this.addChannelData.category ? this.addChannelData.category.join() : null,
                language: this.addChannelData.language ? this.addChannelData.language.join() : null,
                contact_1: this.addChannelData.contact_1,
                resource: this.addChannelData.resource,
                uuid: this.addChannelData.resource === 3 ? window.localStorage.getItem('user-id') : this.addChannelData.uuid,
                contact_2: this.addChannelData.contact_2,
                email_1: this.addChannelData.email_1,
                email_2: this.addChannelData.email_2,
                price: this.addChannelData.price,
                is_page: this.addChannelData.is_page,
                // is_business: this.addChannelData.is_business,
                is_verify: this.addChannelData.is_verify,
                insta_name: this.addChannelData.insta_name,
                total_media: this.addChannelData.total_media,
                followers: this.addChannelData.followers
              }
              if (value) {
                const response = await addInstagram(data)
                if (response.succ) {
                  this.$bvToast.toast('Instagramer added successfully', {
                    title: 'Added Channel',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.resetChannel()
                  this.$refs.addChannelModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add channel error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch((error) => {
              console.log('cancel add.', error)
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style datad>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
